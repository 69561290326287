import {
  CHG_SLIDERS_PAGE,
  DELETE_SLIDER,
  GET_SLIDER,
  GET_SLIDERS,
  POST_SLIDER,
  PUT_SLIDER,
} from "../actions/types";

const initialState = {
  entities: new Map(),
  pages: new Map(),
  currPage: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SLIDERS: {
      const entities = new Map(state.entities);
      const pages = new Map(state.pages);
      const currPage = action.payload.meta.current_page;
      const currItemsPage = [];
      action.payload.data.forEach((project) => {
        entities.set(project.id, project);
        currItemsPage.push(project.id);
      });
      pages.set(currPage, currItemsPage);
      return Object.assign({}, state, {
        entities,
        pages,
        currPage,
        total: action.payload.meta.total,
      });
    }

    case GET_SLIDER:
      return Object.assign({}, state, {
        entities: new Map(state.entities).set(
          action.payload.data.id,
          action.payload.data
        ),
      });

    case POST_SLIDER: {
      const entities = new Map(state.entities);
      const pages = new Map(state.pages);
      entities.set(action.payload.data.id, action.payload.data);
      // add the new entity to current page
      if (state.currPage) {
        pages.get(state.currPage).unshift(action.payload.data.id);
      }
      return Object.assign({}, state, {
        entities,
        pages,
      });
    }

    case PUT_SLIDER:
      return Object.assign({}, state, {
        entities: new Map(state.entities).set(
          action.payload.data.id,
          action.payload.data
        ),
      });

    case DELETE_SLIDER: {
      const pages = new Map(state.pages); // clone pages
      const entities = new Map(state.entities); // clone entities
      const currPage = state.currPage;
      const delId = action.payload.data.id;
      entities.delete(delId);
      // delete it from page
      if (typeof currPage === "number") {
        const currItemsPg = pages.get(currPage);
        const indexOfDel = currItemsPg?.indexOf(delId);
        if (indexOfDel > -1) currItemsPg.splice(indexOfDel, 1);
      }
      return Object.assign({}, state, {
        pages,
        entities,
      });
    }

    case CHG_SLIDERS_PAGE: {
      return Object.assign({}, state, {
        currPage: action.page,
      });
    }

    default:
      return state;
  }
}
