import { WINDOW_RESIZE } from "../actions/types";
const initial_state = {
  window_width: window.innerWidth,
};

export default function (state = initial_state, action) {
  switch (action.type) {
    case WINDOW_RESIZE:
      return {
        ...state,
        window_width: action.payload,
      };
    default:
      return state;
  }
}
