import { GET_SETTINGS, PUT_SETTINGS } from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_SETTINGS:
    case PUT_SETTINGS:
      return Object.assign({}, state, action.payload.data);

    default:
      return state;
  }
}
