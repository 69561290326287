import axios from "./helperComponents/axios";

//auth
export const login = (data) =>
  axios({
    method: "post",
    url: `/admin-login`,
    data: data,
  });

export const getProjects = (page) => {
  return axios({
    method: "get",
    url: `/admin/projects?page=${page}`,
  });
};
export const getProject = (id) => {
  return axios({
    method: "get",
    url: `/admin/projects/${id}`,
  });
};
export const postProject = (data) => {
  return axios({
    method: "post",
    url: "/admin/projects",
    data,
  });
};
export const putProject = (id, data) => {
  return axios({
    method: "put",
    url: `/admin/projects/${id}`,
    data,
  });
};
export const deleteProject = (id) => {
  return axios({
    method: "delete",
    url: `/admin/projects/${id}`,
  });
};

export const getCategories = (page) => {
  return axios({
    method: "get",
    url: `/admin/categories?page=${page}`,
  });
};
export const postCategory = (data) => {
  return axios({
    method: "post",
    url: "/admin/categories",
    data,
  });
};
export const putCategory = (id, data) => {
  return axios({
    method: "put",
    url: `/admin/categories/${id}`,
    data,
  });
};
export const deleteCategory = (id) => {
  return axios({
    method: "delete",
    url: `/admin/categories/${id}`,
  });
};
export const getSettings = () => {
  return axios({
    method: "get",
    url: `/admin/settings`,
  });
};
export const putSettings = (data) => {
  return axios({
    method: "put",
    url: `/admin/settings`,
    data,
  });
};

export const getAdmins = (page) => {
  return axios({
    method: "get",
    url: `/admin/admins?page=${page}`,
  });
};
export const postAdmin = (data) => {
  return axios({
    method: "post",
    url: "/admin/admins",
    data,
  });
};
export const putAdmin = (id, data) => {
  return axios({
    method: "put",
    url: `/admin/admins/${id}`,
    data,
  });
};
export const deleteAdmin = (id) => {
  return axios({
    method: "delete",
    url: `/admin/admins/${id}`,
  });
};

export const getSliders = (page) => {
  return axios({
    method: "get",
    url: `/admin/sliders?page=${page}`,
  });
};
export const getSlider = (id) => {
  return axios({
    method: "get",
    url: `/admin/sliders/${id}`,
  });
};
export const postSlider = (data) => {
  return axios({
    method: "post",
    url: "/admin/sliders",
    data,
  });
};
export const putSlider = (id, data) => {
  return axios({
    method: "put",
    url: `/admin/sliders/${id}`,
    data,
  });
};
export const deleteSlider = (id) => {
  return axios({
    method: "delete",
    url: `/admin/sliders/${id}`,
  });
};

export const getPrinciples = (page) => {
  return axios({
    method: "get",
    url: `/admin/principles?page=${page}`,
  });
};
export const postPrinciple = (data) => {
  return axios({
    method: "post",
    url: "/admin/principles",
    data,
  });
};
export const putPrinciple = (id, data) => {
  return axios({
    method: "put",
    url: `/admin/principles/${id}`,
    data,
  });
};
export const deletePrinciple = (id) => {
  return axios({
    method: "delete",
    url: `/admin/principles/${id}`,
  });
};

// gallery
export const getGallery = (page) => {
  return axios({
    method: "get",
    url: `/admin/category-images?page=${page}`,
  });
};
export const postGallery = (data) => {
  return axios({
    method: "post",
    url: "/admin/category-images",
    data,
  });
};
export const deleteGallery = (id) => {
  return axios({
    method: "delete",
    url: `/admin/category-images/${id}`,
  });
};
