import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
// import { renderRoutes } from 'react-router-config';
import "antd/dist/antd.css";
import "./App.scss";

//redux
import { Provider, connect } from "react-redux";
import store from "./redux/store";
import { resizeWindow } from "./redux/actions/layoutActions";
import { logout } from "./redux/actions/authActions";

import { nthIndex } from "./helperFunctions/functions";
import {
  checkStorage,
  getStorage,
  deleteStorage,
} from "./helperComponents/locale-storage";

import { Show422ErrorMessage } from "./helperFunctions/functions";
import axios from "./helperComponents/axios";
import { notification, message } from "antd";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/login"));

class Main extends Component {
  componentDidMount() {
    window.onresize = () => {
      this.props.resizeWindow(window.innerWidth);
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      checkStorage("keep_login") ? getStorage("keep_login") == "true" : false
    ) {
      // do nothing
    } else {
      var hours = 1; // Reset when last action is more than an hour
      var now = new Date().getTime();
      var setupTime = parseInt(localStorage.getItem("setupTime"));

      if (setupTime == null) {
        props.logout();
        localStorage.setItem("setupTime", now);
      } else {
        if (now - setupTime > hours * 60 * 60 * 1000) {
          deleteStorage("setupTime");
          props.logout();
        } else {
          localStorage.setItem("setupTime", now);
        }
      }
    }
  }

  UNSAFE_componentWillMount() {
    axios.interceptors.response.use(
      (res) => {
        if (res.data.data.message) {
          message.destroy();
          message.success(res.data.data.message);
        }
        return res;
      },
      (error) => {
        const { locale } = this.props.match.params;

        notification.destroy();
        if (error.response) {
          if (error.response.status == 401) {
            this.props.logout();
          }
          if (error.response.status) {
            if (error.response.status == 422) {
              Show422ErrorMessage(error.response.data.errors);
              // notification.error({
              //   message: 'بيانات غير صحيحة',
              // });
            } else if (error.response.status == 403) {
              notification.error({
                message: "عمل غير مصرح به",
              });
            } else if (error.response.status == 429) {
              notification.error({
                message: "طلبات كثيرة جدا",
              });
            } else if (error.response.status == 401) {
              notification.error({
                message: "غير مصدق",
              });
            } else if (error.response.status == 500) {
              notification.error({
                message: "خطأ في الخادم",
              });
            } else if (error.response.status == 503) {
              notification.error({
                message: "الخدمة غير متوفرة",
              });
            } else if (error.response.status == 404) {
              notification.error({
                message: "غير موجود",
              });
            } else {
              notification.error({
                message: "خطأ في الخادم",
              });
            }
          }
        } else {
          notification.error({
            message: "خطأ في الإتصال",
          });
        }

        return Promise.reject(error);
      }
    );
  }

  render() {
    const { is_logged, login_token } = this.props;
    if (login_token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${login_token}`;
    }
    return (
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route
            path="/login/:redirect?"
            render={(props) =>
              is_logged != "true" ? ( //not logged in pages
                <Login {...props} />
              ) : (
                <Redirect to={`/`} />
              )
            }
          />

          <Route
            path={`/`}
            render={(props) =>
              is_logged == "true" ? ( //logged in pages
                <React.Fragment>
                  <Route
                    path="/"
                    name="Home"
                    render={(props) => <DefaultLayout {...props} />}
                  />
                </React.Fragment>
              ) : (
                <Redirect
                  to={`/login/${props.location.pathname.substring(
                    nthIndex(props.location.pathname, "/", 1) + 1,
                    props.location.pathname.length
                  )}${props.location.search}`}
                />
              )
            }
          />
        </Switch>
      </React.Suspense>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const mapStateToProps = (state) => ({
      login_token: state.auth.login_token,
      is_logged: state.auth.is_logged,
    });
    const MainCompenent = connect(mapStateToProps, { resizeWindow, logout })(
      Main
    );
    return (
      <React.Fragment>
        <Provider store={store}>
          <Router>
            <Route path="/" render={(props) => <MainCompenent {...props} />} />
          </Router>
        </Provider>
      </React.Fragment>
    );
  }
}

export default App;
