import { combineReducers } from "redux";
import layoutReducer from "./layoutReducer";
import authReducer from "./authReducer";
import projectsReducer from "./projectsReducer";
import categoriesReducer from "./categoriesReducer";
import settingsReducer from "./settingsReducer";
import adminsReducer from "./adminsReducer";
import slidersReducer from "./slidersReducer";
import principlesReducer from "./principlesReducer";
import galleryReducer from "./galleryReducer";
export default combineReducers({
  layout: layoutReducer,
  auth: authReducer,
  projects: projectsReducer,
  categories: categoriesReducer,
  settings: settingsReducer,
  admins: adminsReducer,
  sliders: slidersReducer,
  principles: principlesReducer,
  gallery: galleryReducer,
});
