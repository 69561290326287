import { LOGIN, LOGOUT } from "../actions/types";
import {
  getStorage,
  checkStorage,
} from "../../helperComponents/locale-storage";
const initial_state = {
  login_token: checkStorage("login_token") ? getStorage("login_token") : "",
  is_logged: checkStorage("is_logged") ? getStorage("is_logged") : "false",
};

export default function (state = initial_state, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        login_token: action.payload.token,
        is_logged: "true",
      };
    case LOGOUT:
      return {
        ...state,
        login_token: "",
        is_logged: "false",
        user_type: "",
      };

    default:
      return state;
  }
}
