import { LOGIN, LOGOUT } from "../actions/types";
import { setStorage } from "../../helperComponents/locale-storage";
import { login } from "../../api";

export const loginAction = (data, keep_login) => (dispatch) => {
  return login(data)
    .then((res) => {
      dispatch({
        type: LOGIN,
        payload: res.data,
      });

      setStorage("login_token", res.data.token);
      setStorage("is_logged", "true");
      setStorage("keep_login", keep_login);
      console.log("RESPONSE RECEIVED: ", res);
      return res;
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
      return Promise.reject(err);
    });
};

export const logout = () => (dispatch) => {
  localStorage.clear();

  dispatch({
    type: LOGOUT,
  });
};
